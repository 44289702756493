var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "title": "Sessions By Device"
    }
  }, [_c('chartjs-component-doughnut-chart', {
    staticClass: "mb-3",
    attrs: {
      "height": 275,
      "data": _vm.chartjsData.doughnutChart.data,
      "options": _vm.chartjsData.doughnutChart.options
    }
  }), _vm._l(_vm.stockData, function (stock, index) {
    return _c('div', {
      key: stock.device,
      staticClass: "d-flex justify-content-between",
      class: index < _vm.stockData.length - 1 ? 'mb-1' : ''
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('feather-icon', {
      class: stock.color,
      attrs: {
        "icon": stock.symbol,
        "size": "16"
      }
    }), _c('span', {
      staticClass: "font-weight-bold ml-75 mr-25"
    }, [_vm._v(_vm._s(stock.device))]), _c('span', [_vm._v("- " + _vm._s(stock.percentage) + "%")])], 1), _c('div', [_c('span', [_vm._v(_vm._s(stock.upDown) + "%")]), _c('feather-icon', {
      class: stock.upDown > 0 ? 'text-success' : 'text-danger',
      attrs: {
        "icon": stock.upDown > 0 ? 'ArrowUpIcon' : 'ArrowDownIcon'
      }
    })], 1)]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }