var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "mb-2"
  }, [_c('span', [_vm._v("You can easily create reuseable chart components. ")]), _c('b-link', {
    attrs: {
      "href": "https://vue-chartjs.org/",
      "target": "_blank"
    }
  }, [_vm._v(" vue-chartjs ")]), _c('span', [_vm._v(" is a wrapper for Chart.js. Read full documnetation ")]), _c('b-link', {
    attrs: {
      "href": "https://vue-chartjs.org/guide/",
      "target": "_blank"
    }
  }, [_vm._v(" here ")])], 1)]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('chartjs-bar-chart')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('chartjs-horizontal-bar-chart')], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('chartjs-line-chart')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('chartjs-radar-chart')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('chartjs-polar-area-chart')], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('chartjs-bubble-chart')], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('chartjs-doughnut-chart')], 1), _c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('chartjs-scatter-chart')], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('chartjs-line-area-chart')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }