var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v("Average Skills")]), _c('b-dropdown', {
    attrs: {
      "dropright": "",
      "variant": "link",
      "toggle-class": "p-0",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "text-body",
          attrs: {
            "icon": "MoreVerticalIcon"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" Last 28 Days ")]), _c('b-dropdown-item', [_vm._v(" Last Month ")]), _c('b-dropdown-item', [_vm._v(" Last Year ")])], 1)], 1), _c('b-card-body', [_c('chartjs-component-polar-area-chart', {
    attrs: {
      "height": 350,
      "data": _vm.chartjsData.polarChart.data,
      "options": _vm.chartjsData.polarChart.options
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }