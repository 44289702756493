var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "title": "Radar Chart"
    }
  }, [_c('chartjs-component-radar-chart', {
    attrs: {
      "height": 355,
      "data": _vm.chartjsData.radarChart.data,
      "options": _vm.chartjsData.radarChart.options
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }